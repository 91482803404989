import React from 'react'
import PropTypes from 'prop-types'
import shortid from 'shortid'
import { Link } from 'gatsby'

import makeStyles from '@material-ui/core/styles/makeStyles'
import {
  Breadcrumbs as MaterialBreadcrumbs,
  Typography,
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    lineHeight: 1.5,
    fontSize: '15px !important',
  },
  breadcrumbLink: {
    fontSize: 15,
    lineHeight: 1.5,
    textDecoration: 'none',
    color: theme.palette.text.secondary,

    '&:hover': {
      color: theme.palette.primary.main,
    },
  },

  breadcrumbNonLink: {
    fontSize: '15px !important',
    fontWeight: 400,
    lineHeight: 1.5,
    whiteSpace: 'nowrap',
    maxWidth: 200,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textDecoration: 'none',
    color: '#000',
  },
}))

const Breadcrumbs = ({ breadcrumbs }) => {
  const classes = useStyles()

  const getBreadcrumb = (breadcrumb, index) => {
    if (index + 1 === breadcrumbs.length) {
      return (
        <Typography
          key={shortid.generate()}
          className={classes.breadcrumbNonLink}
        >
          {breadcrumb.name}
        </Typography>
      )
    }

    return (
      <Link
        key={shortid.generate()}
        to={breadcrumb.path}
        className={classes.breadcrumbLink}
      >
        {breadcrumb.name}
      </Link>
    )
  }

  return (
    <MaterialBreadcrumbs
      aria-label='breadcrumb'
      separator='›'
      className={classes.breadcrumbs}
    >
      {breadcrumbs.map((breadcrumb, index) => getBreadcrumb(breadcrumb, index))}
    </MaterialBreadcrumbs>
  )
}

export default Breadcrumbs

Breadcrumbs.propTypes = {
  breadcrumbs: PropTypes.array.isRequired,
}
