import React, { useContext } from "react"
import PropTypes from "prop-types"
import * as shortid from "shortid"
import "lazysizes"

import { Link, graphql } from "gatsby"

import makeStyles from "@material-ui/core/styles/makeStyles"

import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"

import { localizedSlugPrefix } from "../gatsby/nodeHelpers"
import LangContext from "../contexts/LangContext"
import SEO from "../components/SEO"
import Breadcrumbs from "../components/Breadcrumbs"
import CustomButton from "../components/buttons/CustomButton"
import VideoEmbed from "../components/VideoEmbed"

const useStyles = makeStyles(() => ({}))

const TestimonialsPage = ({
  data: { proceduresPages, testimonialsPage },
  pageContext: { slug, locale, breadcrumbs },
  location,
}) => {
  const classes = useStyles()
  const lang = useContext(LangContext)
  const i18n = lang.i18n[lang.locale]

  const schema = testimonialsPage.data.schema_json.text || ""
  const ogp = testimonialsPage.data.ogp_tags || ""
  const meta = {
    title: testimonialsPage.data.meta_title,
    description: testimonialsPage.data.meta_description,
    keywords: testimonialsPage.data.meta_keywords,
    author: "Medicina Interventionala",
  }

  const testimonials = testimonialsPage.data.testimonials.map(
    ({ testimonial }) => testimonial.document
  )

  const proceduresPage = proceduresPages.edges.map(r => r.node).pop()

  return (
    <>
      <SEO
        schema={schema}
        ogp={ogp}
        meta={meta}
        locale={locale}
        breadcrumbs={breadcrumbs}
      />
      <Container maxWidth="lg">
        <main>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <br />
          <Typography variant="h4" component="h1">
            {testimonialsPage.data.title.text}
          </Typography>
          <br />
          <Grid
            container
            direction="column"
            spacing={4}
            className={classes.cardGrid}
            justifyContent="flex-start"
          >
            {testimonials.map(testimonial => (
              <Grid item key={shortid.generate()}>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={5}>
                    <VideoEmbed video={testimonial.data.video} />
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <Typography variant="h5" component="h2">
                      <Link
                        to={`${localizedSlugPrefix(testimonial)}/${slug}/${
                          testimonial.uid
                        }/`}
                      >
                        {testimonial.data.title.text}
                      </Link>
                    </Typography>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: testimonial.data.description.html,
                      }}
                    />
                    <CustomButton
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      component={Link}
                      to={`/${proceduresPage.uid}/${testimonial.data.procedure.document.data.categories[0].category.document.uid}/${testimonial.data.procedure.document.uid}/`}
                    >
                      {i18n.translations.viewProcedure}
                    </CustomButton>
                  </Grid>
                </Grid>
                <br />
                <Divider variant="middle" />
              </Grid>
            ))}
          </Grid>
        </main>
      </Container>
    </>
  )
}

export default TestimonialsPage

export const query = graphql`
  query TestimonialsPageQuery($slug: String!, $locale: String!) {
    settings: prismicSettings(lang: { eq: $locale }) {
      data {
        site_title
      }
    }

    proceduresPages: allPrismicProcedures(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          uid
          lang
          data {
            title
          }
        }
      }
    }

    testimonialsPage: prismicTestimonialsPage(
      uid: { eq: $slug }
      lang: { eq: $locale }
    ) {
      data {
        title {
          text
        }

        testimonials {
          testimonial {
            document {
              ... on PrismicTestimonial {
                uid
                lang
                data {
                  description {
                    html
                  }
                  procedure {
                    document {
                      ... on PrismicProcedure {
                        uid
                        data {
                          categories {
                            category {
                              document {
                                ... on PrismicProcedureCategory {
                                  uid
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  title {
                    text
                  }
                  video {
                    embed_url
                    thumbnail_url
                    title
                  }
                }
              }
            }
          }
        }
        schema_json {
          text
        }
        ogp_tags {
          content
          property
        }
        meta_title
        meta_description
        meta_keywords
      }
    }
  }
`

TestimonialsPage.propTypes = {
  data: PropTypes.shape({
    settings: PropTypes.object.isRequired,
    proceduresPages: PropTypes.object.isRequired,
    testimonialsPage: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    breadcrumbs: PropTypes.array.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}
