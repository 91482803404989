import React, { useState } from "react"
import makeStyles from "@material-ui/core/styles/makeStyles"
import CardMedia from "@material-ui/core/CardMedia"
import clsx from "clsx"
import YouTubeIcon from "@material-ui/icons/YouTube"

const queryString = require("query-string")

const useStyles = makeStyles(theme => ({
  box: {
    position: "relative",
  },
  placeholder: {
    cursor: "pointer",
    position: "absolute",
    fontSize: 86,
    color: "#FF0000",
    top: "calc(50% - 43px)",
    left: "calc(50% - 43px)",
  },
  placeholderMedia: {
    width: "100%",
    height: 280,
  },
  videoMedia: {
    margin: 0,
    height: 280,
    width: "100%",
    position: "relative",
    backgroundSize: "cover",
  },
}))

const VideoEmbed = ({
  video,
  placeholderMediaClass = {},
  videoMediaClass = {},
}) => {
  const [mediaLoaded, setMediaLoaded] = useState(false)
  const classes = useStyles()

  return (
    <div className={classes.box}>
      {!mediaLoaded && (
        <>
          <div
            className={classes.placeholder}
            role="button"
            tabIndex="0"
            onClick={() => setMediaLoaded(true)}
            onKeyDown={() => setMediaLoaded(true)}
          >
            <YouTubeIcon fontSize="inherit" />
          </div>
          <CardMedia
            className={clsx(classes.placeholderMedia, placeholderMediaClass)}
            image={video.thumbnail_url}
            title={video.title}
          />
        </>
      )}
      {mediaLoaded && (
        <CardMedia
          className={clsx(classes.videoMedia, videoMediaClass)}
          component="iframe"
          src={`https://www.youtube-nocookie.com/embed/${
            queryString.parse(
              video.embed_url.replace("https://www.youtube.com/watch?", "")
            ).v
          }?feature=oembed&modestbranding=1&rel=0&autoplay=1`}
          title={"test"}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      )}
    </div>
  )
}

export default VideoEmbed
